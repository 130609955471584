<template>
  <div>
    <template-language :template="editFile" />
  </div>
</template>
<script>

import editFile from './EditMyChannel.vue'
import templateLanguage from '../../../common/templateLanguage.vue'

export default {
  components: {

    // eslint-disable-next-line vue/no-unused-components
    editFile,
    templateLanguage,
  },
  data() {
    return {
      editFile,
    }
  },

}
</script>
