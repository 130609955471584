<template>
  <div id="edit-channel">
    <b-overlay
      variant="transparent"
      :show="overlay"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-card>
        <b-card-header>
          <b-card-title>{{ $t("infoChannel") }}</b-card-title>
          <b-button
            variant="success"
            @click="updateChannel()"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col>
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('dataGeneric.name')"
              >
                <b-form-input
                  v-model="channelName"
                  maxlength="150"
                  :placeholder="$t('dataGeneric.name')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('dataGeneric.description')"
              >
                <b-form-textarea
                  v-model="channelDescription"
                  :placeholder="$t('dataGeneric.description')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="language == base">
            <b-col>
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('relatedTagLabel')"
              >
                <b-form-input
                  v-model="relatedTag"
                  maxlength="256"
                  :placeholder="$t('relatedTagInfo')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="language == base">
            <image-element-vue
              :image-origin="imageSelected"
              :image-type="'imageData' + language"
              :md-length="5"
              :label="$t('Image')"
              @saveImage="loadImage"
            />
            <image-element-vue
              :image-origin="iconSelected"
              :image-type="'iconData' + language"
              :md-length="5"
              :label="$t('icono')"
              @saveImage="loadImage"
            />
          </b-row>

          <!-- <b-row>
            <b-col>
              <b-form-group label="Slug">
                <h5>{{ slug }}</h5>
              </b-form-group>
            </b-col>
          </b-row> -->
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BCardHeader,
  BCardBody,
  BCard,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, camelToSnake } from '@/store/functions'
import ImageElementVue from '@/views/components/ImageElement.vue'

import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BCardTitle,
    BCardBody,
    BCard,
    BRow,
    ImageElementVue,
    BCol,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      viewName: this.$t('editChannel'),
      viewDetail: this.$t('configChannel'),
      overlay: false,
      channels: [],
      isChild: this.$route.params.id != null ? this.$route.params.id : null,
      userData: getUserData(),
      headers: [],
      hasChild: false,
      channelName: null,
      channelDescription: null,
      image: null,
      icon: null,
      relatedTag: '',
      slug: null,
      imageSelected: null,
      iconSelected: null,
      parent: null,
      id: this.$route.params.id,
      brItems: [{
        id: null,
        text: this.$t('contents.start'),
      },
      ],
      navItems: [{
        id: null,
        text: this.$t('contents.start'),
      },
      ],
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      console.log(error)
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    this.getChannel(this.id)
  },
  methods: {
    buildImageUrl(image) {
      return image == null || image.length === 0
        ? noCover
        : image
    },
    errorImg(e) {
      e.target.src = fileError
    },
    getChannel(id) {
      this.overlay = true
      const { headers } = this
      const query = `{
          extendedTags(id:"${id}") {
            edges {
              node {
                id
                name(lang:"${this.language}")
                description(lang:"${this.language}")
                image
                icon
                slug
                relatedTag
                children {
                  edges {
                    node {
                      name
                      image
                      icon
                      children{
                        totalCount
                      }                      
                    }
                  }
                }
              }
            }
          }
        }`
      axios
        .post('', { query }, { headers })
        .then(result => {
          messageError(result, this)
          const channel = result.data.data.extendedTags.edges[0]

          this.channelName = channel.node.name
          this.channelDescription = channel.node.description
          this.relatedTag = channel.node.relatedTag
          this.imageSelected = channel.node.image
          this.iconSelected = channel.node.icon
          this.slug = channel.node.slug
          this.$emit('return', {
            name: this.channelName, viewName: this.viewName, viewDetail: this.viewDetail,
          })
          this.overlay = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imageData${this.language}`:
          this.image = data
          break
        case `iconData${this.language}`:
          this.icon = data
          break

        default:
          break
      }
    },
    updateChannel() {
      this.overlay = true
      const headers = {
        'Accept-Language': camelToSnake(this.language),
        'Content-Language': camelToSnake(this.language),
      }
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.channelName, type: 'String' },
        { title: `description${this.language}`, value: this.channelDescription, type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'relatedTag', value: this.relatedTag, type: 'String' },
        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateExtendedTags(id:"${this.id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        extendedTag{
            id
            name
            
          }
        }
      }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      if (this.language === this.base) {
        data.append('image', this.image)
        data.append('icon', this.icon)
      }
      axios
        .post('',
          data,
          { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateExtendedTags.extendedTag.name,
            }),
            1, this,
          )
          this.getChannel(this.id)

          this.overlay = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.overlay = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#Channel .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#Channel .card-body {
  padding: 0rem;
}

#Channel .card-body h4 {
  font-size: 1rem !important;
}

#Channel .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#Channel .breadcrumb {
  justify-content: flex-start;
  font-weight: bold;
}

#Channel #button-content {
  padding: 0px;
}

#Channel #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#Channel #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#Channel .cardContent img {
  height: 11vw;
  object-fit: cover;
}
</style>
